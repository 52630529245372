import React, { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useReduxKey from '../../hooks/useReduxKey';

import Price from '../Price/Price';

import './styles.scss';
import { PriceContext } from '../../contexts/PriceProvider';
import { Service } from '../../reducers/services';

const ReservationCardServicesPriceSummary = ({
  services,
}: {
  services: Service[];
}): ReactElement => {
  const { t } = useTranslation();

  const { servicesSubtotal, servicesSubtotalIncl } = useContext(PriceContext);
    const { showPriceInclVat } = useReduxKey('configuration');

  return (
    <div className="card-summary-price">
      <h3>Services</h3>
      <div className="total-overview">
        {services.map((service) => (
          <div className="overview-row" key={`service-${service.id}`}>
            <div className="overview-column">
              {service.name}
              {service.selectedQuantity > 1 && (
                <span> x {service.selectedQuantity}</span>
              )}
            </div>
            <div className="overview-column">
              <Price value={service.price} />
            </div>
          </div>
        ))}
        {showPriceInclVat && (
          <>
            <div className="overview-row">
              <div className="overview-column">{t('vat_21')}</div>
              <div className="overview-column">
                <Price value={servicesSubtotalIncl - servicesSubtotal} />
              </div>
            </div>
            <div className="overview-row title">
              <div className="overview-column">{t('total_incl_vat')}</div>
              <div className="overview-column">
                <Price value={servicesSubtotalIncl} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ReservationCardServicesPriceSummary;
